import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaBookmark, FaShareAlt } from 'react-icons/fa';

const NewsArticle = () => {
  const { language, id } = useParams(); // Access the URL parameters
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isCardClicked, setIsCardClicked] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/news/${language}/article/${id}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const data = await response.json();
        setArticle(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchArticle();
  }, [language, id]);

  const handleBookmark = () => {
    const bookmarkedArticles = JSON.parse(localStorage.getItem('bookmarkedArticles')) || [];
    if (isBookmarked) {
      const updatedBookmarks = bookmarkedArticles.filter((a) => a._id !== article._id);
      localStorage.setItem('bookmarkedArticles', JSON.stringify(updatedBookmarks));
    } else {
      bookmarkedArticles.push(article);
      localStorage.setItem('bookmarkedArticles', JSON.stringify(bookmarkedArticles));
    }
    setIsBookmarked(!isBookmarked);
  };

  const handleCardClick = () => {
    setIsCardClicked(!isCardClicked);
  };

  const handleShare = async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
    const shareableURL = `${baseUrl}/news/${article.language}/article/${article._id}`;
    
    try {
      if (navigator.share) {
        await navigator.share({
          title: article.title,
          text: article.description,
          url: shareableURL,
        });
      } else {
        alert(`Copy and share this link: ${shareableURL}`);
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="relative flex flex-col md:flex-row w-full md:max-w-4xl border p-4 mb-4 md:mx-auto shadow-lg rounded-lg md:rounded-none transition-transform transform group sm:mx-auto"
      onClick={handleCardClick}
    >
      {/* Image Section */}
      <img
        src={article.imageUrl}
        alt={article.title}
        className="w-full h-[300px] md:w-[calc(33.333%+20px)] md:h-[280px] object-cover mb-4 md:mb-0 rounded-t-lg md:rounded-md"
      />

      {/* Text Section */}
      <div className="flex-1 flex flex-col justify-between md:px-6">
        <div>
          <h2 className="mb-1 leading-[1.3] text-lg sm:text-xl font-semibold sm:font-semibold md:text-[20px] md:font-thin md:text-gray-700 md:font-roboto">
            {article.title}
          </h2>
          <p className="text-gray-400 text-xs mb-2">
            {new Date(article.date).toLocaleDateString()}
          </p>
          <p className="text-gray-700 text-sm font-medium leading-[1.4] mb-4 sm:text-base sm:font-medium sm:text-gray-600 md:text-gray-500 md:text-base md:font-normal">
            {article.description}
          </p>
        </div>
        <div>
          <a
            href={article.sourceUrl}
            className="text-gray-900 font-normal text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more at{' '}
            <span className="font-medium text-blue-500 md:text-black-300">
              {article.source}
            </span>
          </a>
        </div>
      </div>

      {/* Icons Section */}
      <div
        className={`absolute bottom-0 left-0 w-full ${
          isCardClicked ? 'bg-gray-100 py-4 opacity-100' : 'opacity-0'
        } transition-all duration-300 ease-in-out md:hidden`}
        style={{
          transform: isCardClicked ? 'translateY(0)' : 'translateY(100%)',
        }}
      >
        <div className="flex justify-center space-x-4">
          {/* Bookmark Button */}
          <button onClick={handleBookmark} className="text-2xl text-gray-400">
            <FaBookmark
              className={isBookmarked ? 'text-blue-500' : 'text-gray-400'}
            />
          </button>
          {/* Share Button */}
          <button onClick={handleShare} className="text-2xl text-gray-400">
            <FaShareAlt />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
