import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/footer.png';

const Footer = () => {
  return (
    <footer className="relative bg-gray-900 text-white py-6">
      {/* Wave effect visible only on mobile */}
      <div className="absolute top-0 left-0 w-full h-8 md:hidden overflow-hidden">
        <svg
          viewBox="0 0 500 80"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full fill-current text-gray-900"
        >
          <path d="M0,40 C150,100 350,0 500,40 L500,00 L0,0 Z"></path>
        </svg>
      </div>

      <div className="container mx-auto px-8 relative">
        {/* Flex container for footer sections */}
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          {/* Left Section: Logo */}
          <div className="flex-1 flex justify-center md:justify-start mb-4 md:mb-0">
            <img src={logo} alt="Logo" className="w-48 md:w-48" />
          </div>

          {/* Vertical Line for separation (hidden on mobile view) */}
          <div className="hidden md:block border-r border-gray-500 h-28 mx-8"></div>

          {/* Center Section: About Us, Terms, Privacy */}
          <div className="flex-1 flex flex-col items-center text-center md:text-left">
            <Link to="/about-us">
              <h3 className="text-lg font-semibold hover:text-red-400">About Us</h3>
            </Link>
            <hr className="my-2 border-gray-600 w-full md:w-40" />
            <p className="text-sm mb-2 hover:text-red-400">Terms and Conditions</p>
            <a
              href="/privacy-policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm hover:text-red-400"
            >
              Privacy Policy
            </a>
          </div>

          {/* Vertical Line for separation (hidden on mobile view) */}
          <div className="hidden md:block border-r border-gray-500 h-28 mx-8"></div>

          {/* Right Section: Contact and Social Media Icons */}
          <div className="flex-1 flex flex-col items-center md:items-end text-center md:text-right pt-2 md:pt-0">
            <Link to="/contact-us">
              <h3 className="text-lg font-semibold hover:text-red-400">Contact Us</h3>
            </Link>
            <hr className="my-2 border-gray-600 w-full md:w-40" />
            <div className="flex space-x-6 md:mt-2">
              {/* Facebook Icon */}
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 transition-transform duration-200 hover:scale-110"
              >
                <FaFacebookF />
              </a>
              {/* Twitter Icon */}
              <a
                href="https://x.com/buzzlynow03"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-300 transition-transform duration-200 hover:scale-110"
              >
                <FaTwitter />
              </a>
              {/* Instagram Icon */}
              <a
                href="https://www.instagram.com/buzzly.now/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-pink-400 transition-transform duration-200 hover:scale-110"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
